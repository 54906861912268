<template lang="pug">
.template-chooser-recommendations
  .row.justify-content-between.align-items-center.mb-6
    .col
      om-heading.font-size-3(h3) {{ $t('recommendedUseCases') }}
    .col-auto(v-if="tacticRecommendationLink")
      om-link.text-nowrap(
        primary
        withIconRight
        data-track-property="change:moreRecommendation"
        @click="onSeeAll(tacticRecommendationLink)"
      ) {{ $t('templateChooser.moreRecommendation') }}
  Skeleton(v-if="isLoading" :rows="1" :cols="3")
  .row.recommendation-tactics(v-else)
    template(v-for="(useCase, index) in items")
      //- template(v-if="index === 1 && getFixTactic")
      //  tactic-card(
      //    @observable="addObservable($event.$el)"
      //    @inited="updateDimensions"
      //    @contentLoaded="onContentLoaded"
      //    @selectFixTactic="selectFixTactic()"
      //    :dimensions="boxDimensions"
      //    :fixTactic="getFixTactic"
      //    :index="index"
      //  )
      tactic-card(
        @observable="addObservable($event.$el)"
        @inited="updateDimensions"
        @contentLoaded="onContentLoaded"
        :dimensions="boxDimensions"
        :key="useCase._id"
        :useCase="useCase"
        :customThemes="userThemes"
        :baseThemes="themes.base"
        :index="index"
        showcase
      )
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import staticTactic from '@/mixins/tactics/staticTactic';
  import previewParentMixin from '@/mixins/previewParent';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import ssrMixin from '@/mixins/ssr';
  import { getAccountIdFromCookie } from '@/util';
  import childRouteMixin from '../../mixins/childRoute';
  import homepageBlockMixin from '../../mixins/homepageBlock';
  import { track } from '@/services/xray'

  const THEME_MAX_CHECKS = 50;

  export default {
    name: 'Recommendations',
    components: {
      Skeleton: () =>
        import('@/components/TemplateChooser/components/skeletons/RecommendationSkeleton.vue'),
      TacticCard: () => import('@/components/TemplateChooser/components/cards/TacticCard.vue'),
    },
    mixins: [
      staticTactic,
      homepageBlockMixin,
      childRouteMixin,
      previewParentMixin,
      observableCollectionMixin,
      ssrMixin,
    ],
    data: () => ({
      fixTactic: null,
      useCasesLoaded: false,
      themesLoaded: false,
      checkTimes: 0,
      themesInterval: null,
    }),
    computed: {
      ...mapGetters(['wizardPreferences', 'getRecommendedTactics']),
      preferredBaseThemeId() {
        return this.wizardPreferences?.customThemeId || this.themes?.base?.[0]?._id;
      },
      preferredThemeId() {
        return this.themes?.user?.[0]?.sourceTheme || this.preferredBaseThemeId;
      },
      useCases() {
        return this.getRecommendedTactics || [];
      },
      preferredBaseThemeName() {
        const theme = this.themes?.base?.find(({ _id }) => _id === this.preferredThemeId);
        return theme?.name ?? undefined;
      },
      items() {
        return (this.useCases || []).slice(0, 3);
      },
      tacticRecommendationLink() {
        return {
          name: 'tactics_recommendation',
          query: {
            customTheme: this.themes?.user?.[0]?._id || this.preferredBaseThemeId,
            theme: this.preferredBaseThemeName,
          },
        };
      },
      userThemes() {
        return this.themes?.user?.map?.((theme) => {
          theme.themeKit.id = theme._id;
          theme.themeKit.source = theme?.sourceTheme;
          return theme;
        });
      },
      isLoading() {
        return !this.useCasesLoaded || !this.themesLoaded;
      }
    },

    watch: {
      themes: {
        handler(value) {
          if (value?.base?.length) {
            if (this.themesInterval) clearInterval(this.themesInterval);
            this.themesLoaded = true;
          } else {
            this.setupThemesChecker()
          }
        },
        deep: true,
        immediate: true
      },
      useCases: {
        handler(value) {
          if (value?.length) {
            this.useCasesLoaded = true;
          }
        },
        deep: true,
        immediate: true
      },
      isLoading: {
        handler(value) {
          if (value) this.$emit('loaded');
        },
        immediate: true
      }
    },

    created() {
      this.initiate();
    },
    mounted() {
      this.checkTimes = 0;
    },
    methods: {
      ...mapActions(['fetchRecommendedTactics']),

      setupThemesChecker() {
        this.themesInterval = setInterval(() => {
          this.checkTimes++;
          this.themesLoaded = !!this.themes?.base;
          if (this.checkTimes > THEME_MAX_CHECKS) {
            clearInterval(this.themesInterval);
            if (!this.themesLoaded) {
              track('recommendationNoThemes', { userId: getAccountIdFromCookie() })
              this.themesLoaded = true
            }
            return
          }
        }, 250)
      },
      async initiate() {
        await this.prepareFixTactic();
        if (!this.getRecommendedTactics?.length) {
          await this.fetchRecommendedTactics();
        } else {
          this.useCasesLoaded = true;
          this.$emit('loaded');
        }
      },
      onSeeAll(tacticRecommendationLink) {
        this.$emit('track', { component: 'Recommended for you', setting: 'show' });
        this.$router.push(tacticRecommendationLink);
      },
    },
  };
</script>
